import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BrandService {

    constructor() {
    this.UrlDatenschutz = 'https://verbands.app/datenschutz';
    this.UrlHomepage = 'https://verbands.app';
    this.MailInfo = 'info@verbands.app';
    this.MailFreigabe = 'service@verbands.app';
  }

  public UrlDatenschutz: string;
  public UrlHomepage: string;
  public MailInfo: string;
  public MailFreigabe: string;
}
