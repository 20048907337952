import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {AuthService} from '../../services/auth-service.service';
import {UserService} from '../../services/user.service';
import {Platform} from '@ionic/angular';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-custom-toolbar',
  templateUrl: './custom-toolbar.component.html',
  styleUrls: ['./custom-toolbar.component.scss'],
})
export class CustomToolbarComponent implements OnInit {

  constructor(
      private userService: UserService,
      public platform: Platform,
      private authService: AuthService
  ) {
    this.showBackButton = true;
    this.showLogo = true;
  }

  @Input() showBackButton: boolean;
  @Input() showLogo: boolean;
  @Output() bellClicked = new EventEmitter<boolean>();

  get userLoggedIn(): Observable<boolean>{
    return this.authService.isLoggedIn();
  }

  ngOnInit() {
  }
}
