import {Component, OnDestroy, OnInit} from '@angular/core';
import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import {Observable, Subscription} from 'rxjs';
import { User } from './models/user';
import {UserService} from './services/user.service';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {routes} from './app-routing.module';
import firebase from 'firebase/compat/app';
import {NotificationService} from './services/notification.service';
import {AuthService} from './services/auth-service.service';
import {ArbeitsgruppenService} from './services/arbeitsgruppen.service';
import { BrandService } from './services/brand.service';
import { TenantService } from './services/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public selectedIndex = 0;
  static appcomponentobject:any;

  appPages: any;

  user: User;
  subscriptions = new Subscription();


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public userService: UserService,
    private notification: NotificationService,
    private angularFireAuth: AngularFireAuth,
    private authService: AuthService) {

    this.angularFireAuth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);

    this.subscriptions.add(this.userService.currentUser$.subscribe(user => {
        console.log('app.component :> currentUser changed');
        this.user = user;
        this.initNavigation();
        this.userService.update_globalCurrantUserAndDependentComponents()
      }));

    this.initializeApp();

  }

  get userLoggedIn(): Observable<boolean>{
    return this.authService.isLoggedIn();
  }

  initializeApp() {
    this.platform.ready().then(() => {

      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.notification.resetBadgeCount();
    });
  }

  ngOnInit() {
    AppComponent.appcomponentobject=this;
    this.angularFireAuth.currentUser.then(value => {
      this.userService.currentFirebaseUser$.next(value);
      this.initNavigation();

    });
  }

  private initNavigation() {
    this.appPages = routes.filter(r =>
        r.data != null && r.data.title != null
        && (r.data.role == null || (this.user != null && this.user.roles != null && this.user.roles.some(ro => ro === r.data.role)))
        && (r.data.platform == null || this.platform.is(r.data.platform))
      ).map(r => ({
      title: r.data.title,
      url: r.data.url,
      icon: r.data.icon
    }));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
