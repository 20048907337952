import { Injectable } from '@angular/core';
import { Camera, CameraResultType } from '@capacitor/camera';
import {FileService} from './file.service';
import {FileUploadTask} from '../models/FileService/fileUploadTask';
import {FileDownloadTask} from '../models/FileService/fileDownloadTask';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PictureService {

  constructor(
      private fileService: FileService,
      private httpWebClient: HttpClient) { }

  async takePhoto(): Promise<FileUploadTask> {
    await Camera.requestPermissions();

    const image = await Camera.getPhoto({
      quality: 75,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      width: 500,
      promptLabelCancel: 'Abbrechen',
      promptLabelHeader: 'Foto',
      promptLabelPhoto: 'Aus der Bibliothek',
      promptLabelPicture: 'Foto aufnehmen'
    });

    const base64String = image.base64String;

    const img: any = await this.getImageDimensions('data:image/' + image.format + ';base64, ' + base64String);

    return this.fileService.uploadBase64(base64String, img.w, img.h, image.format);
  }

  getPhotoUrlFromGuid(guid: string): FileDownloadTask {
    return this.fileService.downloadFile(guid);
  }

  getImageDimensions(file) {
    return new Promise ((resolved, rejected) => {
      const i = new Image();
      i.onload = () => {
        resolved({w: i.width, h: i.height});
      };
      i.src = file;
    });
  }

  downloadBase64ImageFromUrl(url: string, format: string): Promise<string> {
    return this.httpWebClient.request('Get', url, {responseType: 'blob'})
        .toPromise()
        .then(data => {
          return this.convertBlobToBase64(data, format);
        });
  }

  convertBlobToBase64(blob, format = 'jpeg'): Promise<string> {
    return new Promise ((resolved, rejected) => {
      const reader = this.getFileReader();

      reader.onloadend = () => {
        let resultStr = null;
        const data: string = reader.result.toString();
        if (data) {
          const base64 = data.split(',')[1];
          if (base64) {
            resultStr = 'data:image/' + format + ';base64,' + base64;
          }
        }
        resolved(resultStr);
      };

      reader.readAsDataURL(blob);
    });
  }

  // Nötig wegen: https://github.com/ionic-team/capacitor/issues/1564
  private getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any).__zone_symbol__originalInstance;
    return zoneOriginalInstance || fileReader;
  }

}
