/* tslint:disable:max-line-length */
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastController } from '@ionic/angular';
import {NavigationService} from './navigation.service';
import {UserService} from './user.service';
import firebase from 'firebase/compat';
import { AlertController } from '@ionic/angular';
import {Observable, zip} from 'rxjs';
import {map} from 'rxjs/operators';
import {StorageService} from './storage.service';
import {NotificationService} from './notification.service';
import {BrandService} from '../../brand/services/brand.service';
import {FirestoreProxyService} from './firestore-proxy.service';
import { Globals } from './globals';
import {TenantIdGeneratorService} from './tenant-id-generator.service';
import {TenantService} from './tenant.service';
import {NewsFeed} from "../models/newsFeed";
import {User} from "../models/user";

@Injectable({
  providedIn: 'root'
})


export class AuthService {
    static tenentIdOfLogedInUser: any;

  constructor(
      private angularFireAuth: AngularFireAuth,
      private toast: ToastController,
      private alertController: AlertController,
      private nav: NavigationService,
      private userService: UserService,
      private storageService: StorageService,
      private notificationService: NotificationService,


      private tenantService: TenantService
  ) {

      angularFireAuth.authState.subscribe(user => {
          userService.currentFirebaseUser$.next(user);
          if (user) {
              notificationService.updateUserFcmToken();
              //this.userService.update_globalCurrantUserAndDependentComponents()
          }

      });
  }

  brand: BrandService;

  isLoggedIn(): Observable<boolean> {
      return zip(this.userService.currentFirebaseUser$, this.userService.currentUser$)
              .pipe(
                  map(value => {
                      return value[0] && value[0].emailVerified
                          && value[1] && value[1].accountFreigegeben;
                  }));
  }

  registerMember(
      firma: string,
      anrede: string,
      titel: string,
      vorname: string,
      nachname: string,
      email: string,
      passwort: string,
      tenantId: string) {
      console.log("ranMail")
      this.angularFireAuth.createUserWithEmailAndPassword(email, passwort)
          .then(async (userCredential) => {
              this.sendVerificationEmail(userCredential);
              await this.createClientDocument(userCredential, firma, anrede, titel, vorname, nachname, email, tenantId, false);
              const toast =  await this.toast.create({
                  header: 'Firma',
                  message: firma,
                  duration: 5000,
                  color: 'danger'
              });
              toast.present();
              return userCredential;
          })
          .then(() => this.nav.navigateToStart())
          .catch(async (error: any) => {

              if (error.code) {
                  
                  console.log(error.message);
                  const toast =  await this.toast.create({
                      header: 'Fehler',
                      message: error.message,
                      duration: 5000,
                      color: 'danger'
                  });
                  toast.present();
              }
          });
  }

  registerOwner(
      firma: string,
      anrede: string,
      titel: string,
      vorname: string,
      nachname: string,
      email: string,
      passwort: string,
      clientName: string,
      primaryColor: string,
      secondaryColor: string) {

      this.angularFireAuth.createUserWithEmailAndPassword(email, passwort)
        .then(async (userCredential) => {

            this.sendVerificationEmail(userCredential);
            const tenantId = await this.tenantService.createTenant(clientName, primaryColor, secondaryColor);
            await this.tenantService.fetchTenant(tenantId);
            await this.createClientDocument(userCredential, firma, anrede, titel, vorname, nachname, email, tenantId, true);
            return userCredential;
        })
          .then(() => this.nav.navigateToStart())
          .catch(async (error: any) => {

              if (error.code) {
                console.log(error.message);
                const toast =  await this.toast.create({
                  header: 'Fehler',
                  message: error.message,
                  duration: 5000,
                  color: 'danger'
                });
                toast.present();
              }
          });
  }

    private async createClientDocument(
        userCredential: firebase.auth.UserCredential,
        firma: string,
        anrede: string,
        titel: string,
        vorname: string,
        nachname: string,
        email: string,
        tenantId: string,
        isOwner: boolean) {
      console.log("isOwner",isOwner);

        if (userCredential) {
            if (isOwner){
            await this.userService.createUser(
                userCredential.user.uid,
                firma,
                anrede,
                titel,
                vorname,
                nachname,
                email,
                tenantId,
                isOwner);
        }else{
                await this.userService.createUserUnderTenent(
                    userCredential.user.uid,
                    firma,
                    anrede,
                    titel,
                    vorname,
                    nachname,
                    email,
                    tenantId,
                    isOwner);



            }}
    }



    private sendVerificationEmail(userCredential: firebase.auth.UserCredential) {
        if (userCredential && userCredential.user.emailVerified === false) {
            userCredential.user.sendEmailVerification()
                .then(async () => {
                    const alert = await this.alertController.create({
                        header: 'Registrierung',
                        message: 'Wir haben Ihnen soeben eine E-Mail mit einem Link zum Bestätigen Ihrer E-Mailadresse geschickt. Bitte bestätigen Sie ihre E-Mailadresse.',
                        buttons: ['OK']
                    });
                    await alert.present();
                });
        }
    }
    private getUser(uid: string): Promise<User>{
        return new Promise<User>(async (resolve) => {
            console.log('')
            this.userService.getUser(uid).subscribe(x => {
                console.log('x', x);
                return resolve(x);
            });
        });
  }

    private async notifyUserLoggedin(uid: string){
      this.brand = new BrandService();
      const user = await this.getUser(uid);
      console.log('user: ', user);
      this.userService.getUser(uid).toPromise().then(async user => {
          console.log('user', user);
          if (user.accountFreigegeben) {
              const toast =  await this.toast.create({
                  header: 'Login',
                  message: 'Anmeldung erfolgreich',
                  duration: 5000,
                  color: 'success'
              });
              AuthService.tenentIdOfLogedInUser=this.userService.currentUser$.getValue().tenantId;
              toast.present();
              return Promise.resolve();
          }
          else {
              const alert = await this.alertController.create({
                  header: 'Login Freigabe',
                  message: 'Sie sind noch nicht freigeschaltet. Sollte der Zustand länger andauern, dann wenden Sie sich bitte an' +
                            this.brand.MailFreigabe + '.',
                  buttons: ['OK']
              });
              alert.present();
              return Promise.resolve();
          }
      });
  }

    login(email: string, password: string) {
        this.angularFireAuth.signInWithEmailAndPassword(email, password)
            .then(async (userCredential) => {

                this.userService.currentFirebaseUser$.next(userCredential.user);
                const idToken = await userCredential.user.getIdTokenResult();
                await this.tenantService.fetchTenant(idToken.claims.tenantId);
                if (this.isLoggedIn || (userCredential.user.emailVerified === true)) {
                    await this.notifyUserLoggedin(userCredential.user.uid);
                    this.nav.navigateToStart();
                    this.userService.update_globalCurrantUserAndDependentComponents()
                }

                if (userCredential.user != null && userCredential.user.emailVerified === false){
                    const toast = await this.alertController.create({
                        header: 'Login E-Mail',
                        message: 'Bitte bestätigen Sie den Link in der E-Mail, die wir Ihnen gesendet haben.'
                    });
                    toast.present();
                }

                if (!userCredential){
                    const alert = await this.alertController.create({
                        header: 'Login',
                        message: 'E-Mailadresse oder Passwort falsch.'
                    });
                    alert.present();
                }
                return userCredential;
            })
            .catch(async (error: any) => {
              if (error.code) {
                console.log(error.message);
                const toast =  await this.toast.create({
                  header: 'Fehler',
                  message: error.message,
                  duration: 5000,
                  color: 'danger'
                });
                toast.present();
              }
            });
    }


    passwordRecover(passwordResetEmail) {
        return this.angularFireAuth.sendPasswordResetEmail(passwordResetEmail)
            .then(async () => {
                const alert = await this.alertController.create({
                    header: 'Passwort zurücksetzen',
                    message: 'Wir haben Ihnen eine E-Mail mit einem Link zum Ändern Ihres Passwortes gesendet.',
                    buttons: ['OK']
                });
                alert.present();

            }).catch(async (error: any) => {
                if (error.code) {
                    console.log(error.message);
                    const toast =  await this.toast.create({
                        header: 'Fehler',
                        message: error.message,
                        duration: 5000,
                        color: 'danger'
                    });
                    toast.present();
                }
            });
    }

    signOut() {
      this.userService.updateFcmToken(null)
        .then(() => {
            this.angularFireAuth.signOut().then(() => {
                this.tenantService.clearTenant();
                this.nav.navigateToLogin();
            });
        });
    }

}
