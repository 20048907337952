import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UtilService} from '../../services/util.service';
import {ChatMessage} from '../../models/chatMessage';
import {PictureService} from '../../services/picture.service';
import firebase from 'firebase/compat/app';
import {Observable, Subscription} from 'rxjs';
import {Platform} from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import {PictureModalComponent} from './picture-modal.component';

@Component({
  selector: 'app-chat-bubble',
  templateUrl: './chat-bubble.component.html',
  styleUrls: ['./chat-bubble.component.scss'],
})
export class ChatBubbleComponent implements OnInit, OnDestroy{

  @Input() public chatMessage: ChatMessage;
  @Input() public alignmentClass: string;
  @Input() events: Observable<void>;

  public messageStyle: string;
  public pictureStyle: string;
  public skeletonStyle: string;

  public picture: string;
  public pictureLoaded: boolean;
  private pictureIsLoading: boolean;

  private eventsSubscription: Subscription;

  constructor(
      private pictureService: PictureService,
      private platform: Platform,
      public modalController: ModalController)
  {
    this.pictureLoaded = false;
    this.pictureIsLoading = false;
  }

  ngOnInit() {
    this.eventsSubscription = this.events.subscribe(() => this.loadPictureEvent());

    if (this.chatMessage && this.chatMessage.picture)
    {
      this.setDimensions(this.chatMessage.picture.width, this.chatMessage.picture.height);

      setTimeout(() => {
        this.loadPicture(this.chatMessage.picture.url, this.chatMessage.picture.format);
        }, 300);
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  formatEpoch(epoch): string {
    if (epoch instanceof firebase.firestore.Timestamp) {
      return UtilService.getCalendarDay(epoch.seconds);
    }
    return UtilService.getCalendarDay(epoch);
  }

  formatContent(content) {
    return UtilService.linkify(content);
  }

  setDimensions(pictureWidth: number, pictureHeight: number) {
    const screenScale = 0.6;
    const maxScale = 1.2;

    if (pictureWidth <= 0) {
      pictureWidth = 250;
    }
    if (pictureHeight <= 0) {
      pictureHeight = 250;
    }

    this.platform.ready().then(() => {
      const screenWidth =  this.platform.width();

      let scale = 1;
      if (pictureWidth > 0 && pictureHeight > 0) {
        scale = pictureHeight / pictureWidth;
      }

      let width = screenWidth * screenScale;
      let height = width * scale;

      if (scale > maxScale)
      {
        const factor = maxScale / scale;
        width = width * factor;
        height = height * factor;
      }

      this.messageStyle = 'height:' + (height + 10) + 'px;';
      this.pictureStyle = 'width:' + width + 'px; height:' + height + 'px;';
      this.skeletonStyle = 'width:' + width + 'px; height:' + height + 'px;';
    });
  }

  loadPictureEvent() {
    this.loadPicture(this.chatMessage.picture.url, this.chatMessage.picture.format);
  }

  loadPicture(url: string, format: string) {
    if (!this.pictureIsLoading && !this.pictureLoaded) {
      this.pictureIsLoading = true;

      this.pictureService.downloadBase64ImageFromUrl(url, format)
          .then(base64Image => {
            this.picture = base64Image;
            this.pictureLoaded = true;
          });
    }
  }

  async showPicture() {
    const modal = await this.modalController.create({
      component: PictureModalComponent,
      componentProps: {
        picture: this.picture
      },
      swipeToClose: true,
      presentingElement: await this.modalController.getTop()
    });
    return await modal.present();
  }
}
