import { Injectable } from '@angular/core';
import moment from 'moment';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor() {
  }

  static getEpoch(): number {
    return moment().unix();
  }

  static dateTimeToNumber(datetime: string){
    return moment(datetime).unix();
  }

  static getSeconds(time: any): number {
    if (time instanceof firebase.firestore.Timestamp) {
      return time.seconds;
    } else {
      return time;
    }
  }

  static getCalendarFormatedDay(epoch: number): string{
    if (!epoch) {
      return null;
    }

    epoch = this.getSeconds(epoch);

    // const timeString = 'h:mm A';
    return moment(epoch * 1000).locale('de').format('LL');
    // https://momentjs.com/docs/#/i18n/
    /*
        LT : 'HH:mm',
          LTS : 'HH:mm:ss',
          L : 'DD/MM/YYYY',
          LL : 'D MMMM YYYY',
          LLL : 'D MMMM YYYY HH:mm',
          LLLL : 'dddd D MMMM YYYY HH:mm'
     */
  }

  static getCalendarDay(epoch: any): string {
    if (!epoch) {
      return null;
    }

    epoch = this.getSeconds(epoch);

    // const timeString = 'h:mm A';
    return moment(epoch * 1000).locale('de').calendar();
  }

  static onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  static linkify(inputText) {

    // tslint:disable-next-line:max-line-length
    //  const emailReges = /^(?:[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+\.)*[\w\!\#\$\%\&\'\*\+\-\/\=\?\^\`\{\|\}\~]+@(?:(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!\.)){0,61}[a-zA-Z0-9]?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9\-](?!$)){0,61}[a-zA-Z0-9]?)|(?:\[(?:(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\.){3}(?:[01]?\d{1,2}|2[0-4]\d|25[0-5])\]))$/;
    // tslint:disable-next-line:max-line-length
    //  const urlRegrx = /^(?:(?:ht|f)tp(?:s?)\:\/\/|~\/|\/)?(?:\w+:\w+@)?((?:(?:[-\w\d{1-3}]+\.)+(?:com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|edu|co\.uk|ac\.uk|it|fr|tv|museum|asia|local|travel|[a-z]{2}))|((\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)(\.(\b25[0-5]\b|\b[2][0-4][0-9]\b|\b[0-1]?[0-9]?[0-9]\b)){3}))(?::[\d]{1,5})?(?:(?:(?:\/(?:[-\w~!$+|.,=]|%[a-f\d]{2})+)+|\/)+|\?|#)?(?:(?:\?(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)(?:&(?:[-\w~!$+|.,*:]|%[a-f\d{2}])+=?(?:[-\w~!$+|.,*:=]|%[a-f\d]{2})*)*)*(?:#(?:[-\w~!$ |\/.,*:;=]|%[a-f\d]{2})*)?$/i;

    // URLs starting with http://, https://, or ftp://
    const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    let replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" style="line-break: anywhere;">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    const replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank" style="line-break: anywhere;">$2</a>');

    // Change email addresses to mailto:: links.
    const replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" style="line-break: anywhere;">$1</a>');

    // Change carriage return to <br/>
    const replacePattern4 = /\n/gim;
    replacedText = replacedText.replace(replacePattern4, '<br/>');

    return replacedText;
  }
}
