import {Component, Input, OnInit, Output} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor, UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors, Validator, ValidatorFn, Validators
} from '@angular/forms';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-color-input',
  templateUrl: './color-input.component.html',
  styleUrls: ['./color-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ColorInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ColorInputComponent
    }
  ]
})
export class ColorInputComponent implements ControlValueAccessor, Validator {

  private colorCodePattern = '^#(?:[0-9a-fA-F]{3}){1,2}$';
s
  public colorCode: string;
  public touched = false;
  public disabled = false;

  public show_picker=false;
  public state:any;

  @Input() increment: number;
  @Output() onLeave = new EventEmitter();
  @Output() onselectColor = new EventEmitter();


  onChange = (colorCode) => {};
  onTouched = () => {};

  constructor() {
    this.colorCode="#fff"
  }

  public changeComplete(event){

  }



  public onChangeColorCode(event: any){

    const value = event.color.hex;
    this.colorCode=value


  }
  public onChangeCompleteColorCode(event: any){

    const value = event.color.hex;
    this.show_picker=false
    this.markAsTouched();
    this.onChange(value);
  }

  public onBlurColorCode(){
    this.onLeave.emit(this.colorCode);
  }

  public openPicker(){
    this.show_picker=!this.show_picker
  }
  public saveChange(){
    this.show_picker=false;
    this.onselectColor.emit(this.colorCode);
    console.log("emit event")

    //todo save changes
  }

  // Gibt value zum Parent
  public registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  // Gibt zurück ob das Input angeklickt wurde
  public registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  // Schreibt einen Wert ins Formcontrol
  public writeValue(colorCode: string) {
    this.colorCode = colorCode;
  }

  public markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  validate(control: UntypedFormControl) {
    const match = new RegExp(this.colorCodePattern).test(control.value);
    const isNotValid = !match;
    return isNotValid ? {
      validColorCode: false
    } : null;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
