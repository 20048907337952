import {Injectable} from '@angular/core';
import {SettingsService} from './settings.service';
import {Observable, zip} from 'rxjs';
import {Arbeitsgruppe} from '../models/arbeitsgruppe';
import {FirestoreProxyService, Query} from './firestore-proxy.service';
import {Arbeitsgruppen} from '../models/arbeitsgruppen';
import {Globals} from './globals';
import {map, mergeMap} from "rxjs/operators";
import {Feed} from "../models/feed";
import {NewsFeed} from "../models/newsFeed";
import {UtilService} from "./util.service";
import {ChatFeed} from "../models/chatFeed";
import {ToastController} from "@ionic/angular";

@Injectable({
  providedIn: 'root'
})

export class ArbeitsgruppenService {

  private chatpath:string;
  public arbeitsgruppen$: Observable<Arbeitsgruppen>;
  private newspath:string;

  constructor(

      private firestore: FirestoreProxyService,
      private settings: SettingsService,
      private toast: ToastController) {

    this.newspath="news";

      this.arbeitsgruppen$ = settings.getObservable<Arbeitsgruppen>(Globals.arbeitsgruppenId);   //error here doesn't get arbeitsgruppen list
    let artbg=firestore.getDocument("settings","arbeitsgruppen")
    artbg.forEach(x=>{console.log("firestoreSetingObj.",x)})

    console.log("rvE-arbeitsgrupe$",this.arbeitsgruppen$)
    console.log("arbeitsgruppen",this.arbeitsgruppen$.forEach(x=>{console.log("Arbeitsgrupe",x)}) )

    this.arbeitsgruppen$.subscribe(async g => {    //<Err search
      console.log("subscribe=>g",g)
      await this.createArbeitsgruppenFeeds(g);
      if (g?.list) {
        g.list.sort((a, b) => {
          return a.order - b.order;
        });
      }
    });
  }
  public fullReloadOfArbeitsgruppen(){
    this.arbeitsgruppen$ = this.settings.getObservable<Arbeitsgruppen>(Globals.arbeitsgruppenId);   //error here doesn't get arbeitsgruppen list
    let artbg=this.firestore.getDocument("settings","arbeitsgruppen")
    artbg.forEach(x=>{console.log("firestoreSetingObj.",x)})
  }

  private async getArbeitsgruppenNewsFeeds(): Promise<NewsFeed[]>{
    return new Promise<NewsFeed[]>(async (resolve) => {
      let newsFeeds: NewsFeed[] = [];
      this.getFeeds<NewsFeed>(this.newspath).pipe(map( feeds =>
          {
            const res = feeds.filter(f => f.arbeitsgruppe != null
                );
            newsFeeds.push(...res);
            resolve(newsFeeds);
          }
      )).subscribe(x => {

      })
    });
  }

  private async getArbeitsgruppenChatFeeds(): Promise<NewsFeed[]>{
    return new Promise<ChatFeed[]>(async (resolve) => {
      let chatFeeds: ChatFeed[] = [];
      this.getFeeds<ChatFeed>(Globals.chatsPath).pipe(map( feeds =>
          {
            const res = feeds.filter(f => f.arbeitsgruppe != null
            );
            chatFeeds.push(...res);
            resolve(chatFeeds);
          }
      )).subscribe(x => {

      })
    });
  }

  private async arbeitsgruppeChanged(arbeitsgruppe: Arbeitsgruppe): Promise<boolean> {
    return new Promise<boolean>(async (resolve) => {
      this.arbeitsgruppen$.subscribe(ag => {
        ag.list.forEach(agList => {
          if (arbeitsgruppe.id === agList.id){
            if (arbeitsgruppe !== agList){
              resolve(true);
            }else{
              resolve(false);
            }
          }
        })
      })
    });
  }

  public async initArbeitsgruppe(){
    const arbeitsgruppen = await this.getArbeitsgruppenArrayWithAddedAg(null);
    await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, arbeitsgruppen);
  }

  public async updateArbeitsgruppe(arbeitsgruppe: Arbeitsgruppe){
    const agChanged = this.arbeitsgruppeChanged(arbeitsgruppe);

    if (agChanged){
      const arbeitsgruppen = await this.getArbeitsgruppenArrayWithAddedAg(null);
      const index = arbeitsgruppen.list.map(x => x.id).indexOf(arbeitsgruppe.id);

      if (index > -1){
        arbeitsgruppen.list.splice(index, 1);
        arbeitsgruppen.list.push(arbeitsgruppe);
        await this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, arbeitsgruppen);
      }
    }
  }

  private async createArbeitsgruppenFeeds(arbeitsgruppen: Arbeitsgruppen) {
    let newsToAdd: Arbeitsgruppe [] = [];
    let chatsToAdd: Arbeitsgruppe [] = [];

    const newsFeeds = await this.getArbeitsgruppenNewsFeeds();
    const chatFeeds = await this.getArbeitsgruppenChatFeeds();

    //ist die Arbeitsgruppe im Feed vorhanden?
    // nein = neu
    // ja = update nur wenn anders? Im Feed ändert sich doch nix wenn die AG geändert wird

    // ist im Feed eine, die nicht in ag vorhanden ist? Dann den Feed als gelöscht markieren.

    //Neu erstellen

    console.log("Arbeitsgruppen",arbeitsgruppen)

      arbeitsgruppen?.list.forEach(ag => {
        let foundNews = false;
        let foundChat = false;

        for (const news of newsFeeds){
          if (news.arbeitsgruppe === ag.id){
            foundNews = true;
          }
        }

        for (const chat of chatFeeds){
          if (chat.arbeitsgruppe === ag.id){
            foundChat = true;
          }
        }

        if (!foundNews){
          newsToAdd.push(ag);
        }

        if (!foundChat){
          chatsToAdd.push(ag);
        }
      });


    for (const ag of newsToAdd){
      const newFeed = this.createAndGetFeed(ag.id, false);
      this.firestore.addDocument(Globals.newsPath, newFeed);
    }

    for (const ag of chatsToAdd){
      const newFeed = this.createAndGetFeed(ag.id, false);
      this.firestore.addDocument(Globals.chatsPath, newFeed);
    }
  }

  createAndGetFeed(agId: number, feedDeleted: boolean): Feed{
    const feed: Feed = {
      arbeitsgruppe: agId,
      isArbeitsgruppenFeed: true,
      name: '',
      time: UtilService.getEpoch(),
      deleted: feedDeleted
    };
    return feed;
  }

  getFeeds<T>(path: string, where?: Query[]): Observable<T[]>{
    return this.arbeitsgruppen$
        .pipe(map(ags =>
            {
              return ags;
            }),
            mergeMap(arbeitsgruppen => {
              return this.firestore.query<T>(
                  path,
                  where,
                  {fieldPath: 'time', directionStr: 'desc'}
              ).pipe(map(feeds => {

                feeds.forEach(fe => {
                  let f: Feed;
                  f = fe as unknown as Feed;
                  if (f.arbeitsgruppe != null) {
                    const ag = arbeitsgruppen.list.find(x => x.id === f.arbeitsgruppe);
                    if (ag) {
                      f.name = ag.name;
                    }
                  }
                });
                return feeds;
              }));
            }));
  }

  private async getArbeitsgruppenNextId(): Promise<number> {
    return new Promise<number>(async (resolve) => {
      console.log("arbgruppen",this.arbeitsgruppen$)

      this.arbeitsgruppen$.forEach(value => {
        console.log("list",value);
        if (value ===undefined){
          return 1
        }
        resolve(value.list.length + 1);
        return value.list.length + 1;
      })
      return 1;
    });
  }

  async createArbeitsgruppe(agName: string) {
    const nextId = await this.getArbeitsgruppenNextId();

    let neueArbeitsgruppe: Arbeitsgruppe = {
      id: nextId,
      order: nextId,
      name: agName
    };

    const arbeitsgruppen = await this.getArbeitsgruppenArrayWithAddedAg(neueArbeitsgruppe);

    return this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId, arbeitsgruppen);
  }

  public async getArbeitsgruppenArrayWithAddedAg(neueArbeitsgruppe: Arbeitsgruppe): Promise<Arbeitsgruppen> {
    return new Promise<Arbeitsgruppen>(async (resolve) => {

      const arbeitsgruppen: Arbeitsgruppen = {
        list: []
      }

      this.arbeitsgruppen$.subscribe(x => {
        if(neueArbeitsgruppe){
          arbeitsgruppen.list.push(neueArbeitsgruppe)
        }
        if (x?.list){
          arbeitsgruppen.list.push(...x.list);
        }
        resolve(arbeitsgruppen);
      })
    });
  }

  getArbeitsgruppenIconName(arbeitsgruppe: Arbeitsgruppe): string{
    if (arbeitsgruppe.archived){
      return 'repeat-outline';
    }
    return 'archive-outline';
  }


  async archiveArbeitsgruppe(arbeitsgruppe: Arbeitsgruppe) {
    const arbeitsgruppen = await this.getArbeitsgruppenArrayWithAddedAg(null);
    const agList = arbeitsgruppen.list;
    const index = agList.map(x => x.id).indexOf(arbeitsgruppe.id);
    let toastText = '';

    if (index > -1){
      if (arbeitsgruppe.archived){
        agList[index].archived = false;
        toastText = 'Arbeitsgruppe erfolgreich wiederhergestellt!';
      }else{
        agList[index].archived = true;
        toastText = 'Arbeitsgruppe erfolgreich gelöscht!';
      }
      const arbeitsgruppenList: Arbeitsgruppen = {
        list: agList
      }

      this.settings.update<Arbeitsgruppen>(Globals.arbeitsgruppenId,  arbeitsgruppenList);

      const toast =  await this.toast.create({
        message: toastText,
        duration: 5000,
        color: 'success'
      });
      toast.present();
      }
  }
}

