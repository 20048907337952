import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import {NavigationService} from '../services/navigation.service';
import {map} from 'rxjs/operators';
import {AuthService} from '../services/auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthnGuard implements CanActivate {

  constructor(
      private authService: AuthService,
      private userService: UserService,
      private nav: NavigationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.authService.isLoggedIn()
        .pipe(map(loggedIn => {
          if (!loggedIn) {
            this.nav.navigateToLogin();
            return false;
          }
          return true;
        }));
  }
}
