import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AuthnGuard} from './guards/authn.guard';
import {AuthzGuard} from './guards/authz.guard';

export const routes: Routes = [
  {
    path: '',
    redirectTo: 'start',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register/:email',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    data: {
      title: 'Start',
      url: '/start',
      icon: 'home'
    },
    path: 'start',
    loadChildren: () => import('./pages/start/start.module').then( m => m.StartPageModule)
  },
  {
    path: 'start/:contentId',
    loadChildren: () => import('./pages/feed-content/feed-content.module').then( m => m.FeedContentPageModule),
    canActivate: [AuthnGuard]
  },
  {
    data: {
      title: 'News',
      url: '/news',
      icon: 'newspaper'
    },
    path: 'news',
    loadChildren: () => import('./pages/news-list/news-list.module').then(m => m.NewsListPageModule),
    canActivate: [AuthnGuard]
  },
  {
    path: 'news/:feedId',
    loadChildren: () => import('./pages/news-feed/news-feed.module').then( m => m.NewsFeedPageModule),
    canActivate: [AuthnGuard]
  },
  {
    path: 'news/:feedId/content/:docId',
    loadChildren: () => import('./pages/news-feed-content/news-feed-content.module').then( m => m.NewsFeedContentPageModule),
    canActivate: [AuthnGuard]
  },
  {
    data: {
      role: 'redaktion',
    },
    path: 'news/:feedId/new',
    loadChildren: () => import('./pages/news-new/news-new.module').then( m => m.NewsNewPageModule),
    canActivate: [AuthnGuard, AuthzGuard]
  },
  {
    data: {
      title: 'Chats',
      url: '/chats',
      icon: 'chatbubble-ellipses'
    },
    path: 'chats',
    loadChildren: () => import('./pages/chats/chats.module').then( m => m.ChatsPageModule),
    canActivate: [AuthnGuard]
  },
  {
    path: 'chats/:id',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule),
    canActivate: [AuthnGuard]
  },
  {
    path: 'chats/:id/details',
    loadChildren: () => import('./pages/chat-details/chat-details.module').then( m => m.ChatDetailsPageModule)
  },
  {
    data: {
      title: 'Kontakte',
      url: '/contacts',
      icon: 'people'
    },
    path: 'contacts',
    loadChildren: () => import('./pages/contacts/contacts.module').then( m => m.ContactsPageModule),
    canActivate: [AuthnGuard]
  },
  {
    data: {
      title: 'Termine',
      url: '/events',
      icon: 'calendar'
    },
    path: 'events',
    loadChildren: () => import('./pages/calendar/calendar.module').then( m => m.CalendarPageModule),
    canActivate: [AuthnGuard]
  },
  {
    path: 'calendar-event-new/:calendarId',
    loadChildren: () => import('./pages/calendar-event-new/calendar-event-new.module').then( m => m.CalendarEventNewPageModule),
    canActivate: [AuthnGuard]
  },
  {
    data: {
      title: 'Profil',
      url: '/profile',
      icon: 'person'
    },
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthnGuard]
  },
  {
    path: 'contacts/:uid',
    loadChildren: () => import('./pages/contact-details/contact-details.module').then( m => m.ContactDetailsPageModule)
  },
  {
    data: {
      title: 'Admin - Arbeitsgruppen',
      url: '/admin-working-groups',
      icon: 'apps',
      role: 'admin',
      platform: 'desktop'
    },
    path: 'admin-working-groups',
    loadChildren: () => import('./pages/admin-working-groups/admin-working-groups.module').then( m => m.AdminWorkingGroupsPageModule),
    canActivate: [AuthnGuard, AuthzGuard]
  },
  {
    data: {
      title: 'Admin - News',
      url: '/admin-news',
      icon: 'newspaper',
      role: 'admin',
      platform: 'desktop'
    },
    path: 'admin-news',
    loadChildren: () => import('./pages/admin-news/admin-news.module').then( m => m.AdminNewsPageModule),
    canActivate: [AuthnGuard, AuthzGuard]
  },
  {
    data: {
      title: 'Admin - Chats',
      url: '/admin-chats',
      icon: 'chatbubble-ellipses',
      role: 'admin',
      platform: 'desktop'
    },
    path: 'admin-chats',
    loadChildren: () => import('./pages/admin-chats/admin-chats.module').then( m => m.AdminChatsPageModule),
    canActivate: [AuthnGuard, AuthzGuard]
  },
  // {
  //   data: {
  //     title: 'Admin - Termine',
  //     url: '/admin-chats',
  //     icon: 'calendar',
  //     role: 'admin',
  //     platform: 'desktop'
  //   },
  //   path: 'admin-chats',
  //   loadChildren: () => import('./pages/admin-chats/admin-chats.module').then( m => m.AdminChatsPageModule),
  //   canActivate: [AuthnGuard, AuthzGuard]
  // },
  {
    data: {
      title: 'Admin - Profile',
      url: '/admin-profile',
      icon: 'people',
      role: 'admin',
      platform: 'desktop'
    },
    path: 'admin-profile',
    loadChildren: () => import('./pages/admin-profile/admin-profile.module').then( m => m.AdminProfilePageModule),
    canActivate: [AuthnGuard, AuthzGuard]
  },
  {
    path: 'contact-selector',
    loadChildren: () => import('./pages/contact-selector/contact-selector.module').then( m => m.ContactSelectorPageModule),
    canActivate: [AuthnGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
