import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {FirestoreProxyService} from './firestore-proxy.service';
import {map, publishReplay, refCount} from 'rxjs/operators';
import {UtilService} from './util.service';
import {Globals} from './globals';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  constructor(private firestore: FirestoreProxyService,) {}

  get<T>( docid: string): Observable<T> {
      return this.firestore.getDocument<T>(Globals.settingsPath, docid);
  }

  getObservable<T>( docid: string): Observable<T> {
      return this.firestore.getDocumentConnectedObservable<T>(Globals.settingsPath, docid);
  }

  update<T>( docid: string, doc: T){
      return this.firestore.setDocument(docid, Globals.settingsPath, doc);
  }

}
