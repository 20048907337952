import { Component, OnDestroy, OnInit } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import * as xml2js from 'xml2js';
import { Rss } from './rss';
import { Subscription, timer } from 'rxjs';
import { Platform } from '@ionic/angular';
import { HTTP } from '@ionic-native/http/ngx';
import {BrandService} from '../../../brand/services/brand.service';

@Component({
  selector: 'app-start-page',
  templateUrl: './start-page.component.html',
  styleUrls: ['./start-page.component.scss'],
})

export class StartPageComponent implements OnInit, OnDestroy {

  titleTextCentered: string;
  randomTextSubscription: Subscription;
   startInfoListVerbantPositivPoints: string[] = ['Chats und Events' , 'Einfach und intuitiv' ,
    'Im Verbands-Design' ,
    'Einfache Integration in jede IT' ,
    'DSGVO by Design' ];

  textList = []; // ['Der Verband in der Tasche', 'Mobil', 'Direkt', 'Intuitiv'];
  i = 0;

  constructor(
      private httpWebClient: HttpClient,
      private httpNativeClient: HTTP,
      public platform: Platform,
      private brand: BrandService) {



    const randomTextTimer = timer(0, 4000);
    this.randomTextSubscription = randomTextTimer.subscribe( _ => {
      if (this.i >= this.textList.length){
        this.i = 0;
      }
      this.titleTextCentered = this.textList[this.i++];
    });
  }


  ngOnInit() {

  }

  ngOnDestroy() {
    this.randomTextSubscription.unsubscribe();
  }
}
