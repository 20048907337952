export class Globals{
    public static chatsPath = 'chats';   //seduced for removal
    public static chatParticipantsFieldName = 'participants';
    public static newsPath = 'news';
    public static messagesCollectionName = 'feed';
    public static messageIdName = 'messageId';
    public static settingsPath = 'settings';

    public static currentTenentId:string=null;

    public static arbeitsgruppenId = 'arbeitsgruppen';
    public static calendarPath = 'calendar';
    public static eventsCollectionName = 'events';
    public static tenantPath = 'tenant';
}
