import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  constructor(private router: Router) { }

  navigateToRegister(email: string) {
    this.router.navigate(['/register', email]);
  }

  navigateToLogin() {
    this.router.navigate(['/login'], { replaceUrl: true });
  }

  navigateToStart() {
    this.router.navigate(['/start'], { replaceUrl: true });
  }

  navigateToChatDetails(chatId: string) {
    this.router.navigate(['/chats', chatId, 'details']);
  }

  navigateToChat(chatId: string) {
    this.router.navigate(['/chats', chatId]);
  }

  navigateToChats() {
    this.router.navigate(['/chats'], {replaceUrl: true});
  }

  navigateToNewsFeed(feedId: string) {
    this.router.navigate(['/news', feedId]);
  }

  navigateToContact(contactId: string) {
    this.router.navigate(['/contacts', contactId]);
  }

  navigateToNewEvent(calendarId: string) {
    this.router.navigate(['/calendar-event-new', calendarId]);
  }

  navigateToCalendar() {
    this.router.navigate(['/events']);
  }
}
