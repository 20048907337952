import { Injectable } from '@angular/core';
import {Tenant} from '../models/tenant';
import {BehaviorSubject, Observable} from 'rxjs';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Globals} from './globals';
import {FirestoreProxyService} from "./firestore-proxy.service";
import {TenantIdGeneratorService} from "./tenant-id-generator.service";
import {ArbeitsgruppenService} from "./arbeitsgruppen.service";
//gjdfhhfhdjsh
@Injectable({
  providedIn: 'root'
})
export class TenantService {

  private t$ = new BehaviorSubject<Tenant>(null);
  public tenant$ = this.t$.asObservable();

  constructor(private angularFirestore: AngularFirestore,
              private tenantIdGeneratorService: TenantIdGeneratorService,
              private firestore: FirestoreProxyService,
              private arbeitsgruppenService:ArbeitsgruppenService) {

    this.tenant$.subscribe(tenant => {


      this.updateCssColorVars(tenant.primaryColor,tenant.secondaryColor)
      console.log('currentTenant', tenant);
    });



  }

  async fetchTenant(tenantId: string): Promise<Tenant> {
    const doc = await this.angularFirestore.collection(Globals.tenantPath).doc(tenantId).get().toPromise();
    const tenant = doc.data() as Tenant;
    console.log('tenant3', tenant);
    this.t$.next(tenant);
    return tenant;
  }

  fetchTenantNew<T>(tenantId: string): Observable<T> {
    console.log('tId', tenantId);
    console.log('path ', Globals.tenantPath);
    const tenant =  this.angularFirestore.collection(Globals.tenantPath).doc<Tenant>(tenantId).get().subscribe(x => {
      console.log('der tenant', x.data());
    })

    return null;
  }
  public async createTenant(
      clientName: string,
      primaryColor: string,

      secondaryColor: string): Promise<string> {

    const tenantId = await this.tenantIdGeneratorService.generateTenantId();
    Globals.currentTenentId=tenantId;
    const tenantDocument = {
      tenantId,
      clientName,
      primaryColor,
      secondaryColor
    };

    const tenantExists = await this.firestore.documentExists(tenantId, Globals.tenantPath);

    if (!tenantExists) {
      await this.firestore.setDocumentWithoutTenant(tenantId, Globals.tenantPath, tenantDocument);
      await this.arbeitsgruppenService.initArbeitsgruppe();
    }

    return tenantId;
  }

  async updateTenant(tenant: Partial<Tenant>) {
    await this.angularFirestore.collection(Globals.tenantPath).doc(tenant.tenantId).update(tenant);
    const updatedTenant = await this.fetchTenant(tenant.tenantId);
    //this.t$.next(updatedTenant);
  }
  updateCssColorVars(colorPrime,colorSecond){
    document.documentElement.style.setProperty(`--ion-color-primary`, colorPrime);
    console.log("set")
    document.documentElement.style.setProperty(`--ion-color-secondary`, colorSecond);
  }

  clearTenant() {
    this.t$.next(null);
  }

  async isTenantExisting(tenantId: string): Promise<boolean> {
    const doc = await this.angularFirestore.collection<any>(Globals.tenantPath).doc('all').get().toPromise();
    if (!doc.exists) {
      return false;
    }
    const tenants: string[] = doc.data().tenants;
    return tenants.indexOf(tenantId) > -1;
  }
}
