import firebase from 'firebase/compat';
import {Subject} from 'rxjs';

export class FileUploadTask {

    uploadCompleted = new Subject<{url: string, guid: string}>();
    progressPercentage = new Subject<number>();
    error = new Subject<string>();

    constructor(
        private uploadTask: firebase.storage.UploadTask,
        uuid: string,
        public width: number,
        public height: number,
        public format: string) {

        uploadTask.on('state_changed',
            (snapshot) => {
                this.progressPercentage.next((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            },
          (error) => {
              if (error.code !== 'storage/canceled') { // User cancelled
                  this.error.next(error.message);
              }
            },
            () => {
                uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
                    this.uploadCompleted.next({url: downloadURL, guid: uuid});
                });
            }
        );
    }

    pause(): boolean {
        return this.uploadTask.pause();
    }

    cancel(): boolean {
        return this.uploadTask.cancel();
    }

    resume(): boolean {
        return this.uploadTask.resume();
    }
}
