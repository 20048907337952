import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CustomToolbarComponent} from '../../components/custom-toolbar/custom-toolbar.component';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {ChatBubbleComponent} from '../../components/chat-bubble/chat-bubble.component';
import {ContactListComponent} from '../../components/contact-list/contact-list.component';
import {PictureModalComponent} from '../../components/chat-bubble/picture-modal.component';
import {ImprintComponent} from '../../../brand/components/imprint/imprint.component';
import {StartPageComponent} from '../../../brand/components/start-page/start-page.component';
import {EventDetailComponent} from '../../components/event-detail/event-detail.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { TenantIdInputComponent } from 'src/app/components/client-code-input/tenant-id-input.component';
import {ColorInputComponent} from '../../components/test-input-control/test-input/color-input.component';
import {ColorSketchModule} from "ngx-color/sketch";
import {ColorChoiseComponent} from "../../components/color-choise/color-choise.component";

@NgModule({
    declarations: [
        CustomToolbarComponent,
        ChatBubbleComponent,
        ContactListComponent,
        PictureModalComponent,
        ImprintComponent,
        StartPageComponent,
        EventDetailComponent,
        TenantIdInputComponent,
        ColorInputComponent,
        ColorChoiseComponent
    ],
    exports: [
        CustomToolbarComponent,
        ChatBubbleComponent,
        ContactListComponent,
        ImprintComponent,
        StartPageComponent,
        EventDetailComponent,
        TenantIdInputComponent,
        ColorInputComponent,
        ColorChoiseComponent
    ],
    imports: [
        CommonModule,
        RouterModule,
        IonicModule,
        ReactiveFormsModule,
        FormsModule,
        ColorSketchModule
    ],
  providers: [
  ]
})
export class SharedModule { }
