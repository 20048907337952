import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {from, Observable} from 'rxjs';
import { UserService } from '../services/user.service';
import {NavigationService} from '../services/navigation.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthzGuard implements CanActivate {

  constructor(
      private user: UserService,
      private nav: NavigationService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      if (route == null || route.data == null || route.data.role == null) {
          return true;
      }

      return this.user.isInRole(route.data.role);
  }
}

