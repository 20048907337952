import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import firebase from 'firebase/compat/app';
import firestore = firebase.firestore;
import {Globals} from './globals';

export interface Query{
    fieldPath: string;
    filterOperator: WhereFilterOp;
    value: any;
}
export type WhereFilterOp =
    | '<'
    | '<='
    | '=='
    | '!='
    | '>='
    | '>'
    | 'array-contains'
    | 'in'
    | 'array-contains-any'
    | 'not-in';

export interface Order {
    fieldPath: string;
    directionStr?: OrderByDirection;
}

export type OrderByDirection = 'desc' | 'asc';

export type DocumentChangeType = 'added' | 'removed' | 'modified';

@Injectable({
    providedIn: 'root'
})
export class FirestoreProxyService {



    constructor(
        private angularFirestore: AngularFirestore,
        ) {


    }

    prependTenant(path: string): string {
        console.log('TiD', Globals.currentTenentId);
        return `${Globals.tenantPath}/${Globals.currentTenentId}/${path}`;
    }

    setDocumentWithoutTenant<T>(id: string, path: string, obj: T): Promise<void> {
        return this.angularFirestore.collection(path).doc<T>(id).set(obj);
    }

    async addDocument<T>(path: string, document: T): Promise<string> {
        const addedDoc = await this.angularFirestore.collection<T>(this.prependTenant(path)).add(document);
        return addedDoc.id;
    }

    setDocument<T>(id: string, path: string, obj: T): Promise<void> {
        return this.angularFirestore.collection(this.prependTenant(path)).doc<T>(id).set(obj);
    }


    async documentExists(id: string, path: string): Promise<boolean>{
      const result = await this.angularFirestore.collection(this.prependTenant(path)).doc(id).get().toPromise();
      return result.exists;
    }

    async updateDocument<T>(path: string, id: string, obj: T): Promise<string> {
        await this.angularFirestore.collection(this.prependTenant(path)).doc<T>(id).update(obj);
        return id;
    }

    mergeDocument<T>(id: string, path: string, obj: T): Promise<void> {
      return this.angularFirestore.collection(this.prependTenant(path)).doc(id).set(
          obj, {merge: true}
      );
    }

    getDocument<T>(path: string, docId: string): Observable<T> {
        console.log('path: ', this.prependTenant(path));

      return this.angularFirestore.collection(this.prependTenant(path)).doc<T>(docId).get()
          .pipe(map(x => {
            return {...x.data(), docId: x.id};
          }));
    }

      stateChanges<T>(path: string,
                      where?: Query[],
                      order: Order = {
                          fieldPath: 'time',
                          directionStr: 'desc'
                      },
                      // limit: number = 20, // Anforderung Kunde: Es sollen alle Daten geladen werden.
                      documentChangeType: DocumentChangeType[] = ['added']
                      ): Observable<T[]> {
          return this.angularFirestore
              .collection<T>(this.prependTenant(path), ref => {
                  let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                  if (where && where.length > 0) {
                      where.forEach(q => {
                          query = query.where(q.fieldPath, q.filterOperator, q.value);
                      });
                  }
                  if (order) { query = query.orderBy(order.fieldPath, order.directionStr); }
                  // if (limit) { query = query.limit(limit); }
                  return query;
              })
              .stateChanges(documentChangeType)
              .pipe(map(x => {
                  return x.filter(y => documentChangeType.some(dt => dt === y.type)).map(y => {
                      return {...y.payload.doc.data(), docId: y.payload.doc.id
                  };
                  });
              }));
      }

    deleteDocument(path: string, id: string) {
        return this.angularFirestore.collection(this.prependTenant(path)).doc(id).delete();
    }

    query<T>(path: string, where?: Query[], order?: Order, limit?: number): Observable<T[]> {
        return this.angularFirestore
            .collection<T>(this.prependTenant(path), ref => {
                let query: firebase.firestore.CollectionReference | firebase.firestore.Query = ref;
                if (where && where.length > 0) {
                    where.forEach(q => {
                        if (q && q.fieldPath && q.value) {
                            query = query.where(q.fieldPath, q.filterOperator, q.value);
                        }
                    });
                }
                if (order) { query = query.orderBy(order.fieldPath, order.directionStr); }
                if (limit) { query = query.limit(limit); }
                return query;
            })
            .valueChanges({idField: 'docId'});
    }

    getCollection<T>(path: string): Observable<T[]> {
      return this.angularFirestore.collection<T>(this.prependTenant(path)).valueChanges({idField: 'docId'});
    }

    queryTakeFirst<T>(path: string, where?: Query[], order?: Order, limit?: number): Observable<T[]> {
        return this.query<T>(this.prependTenant(path), where, order, limit).pipe(take(1));
    }

    getDocumentConnectedObservable<T>(path: string, docId: string): Observable<T> {
        console.log('fetch users from ' + this.prependTenant(path));
      return this.angularFirestore.collection(this.prependTenant(path)).doc<T>(docId).valueChanges();
    }

    getDocumentConnectedObservableWithTenant<T>(tenantId: string, path: string, docId: string): Observable<T> {
        return this.angularFirestore.collection(`${Globals.tenantPath}/${tenantId}/${path}`).doc<T>(docId).valueChanges();
    }

    addValueToArray(id: string, path: string, key: string, arrayValue: any[]) {
      return this.angularFirestore.collection(this.prependTenant(path)).doc(id)
          .update({
            [key]: firestore.FieldValue.arrayUnion(...arrayValue)
          });
    }

    removeValueFromArray(id: string, path: string, key: string, arrayValue: any) {
      return this.angularFirestore.collection(this.prependTenant(path)).doc(id)
          .update({
              [key]: firestore.FieldValue.arrayRemove(arrayValue)
          });
      }

    removeField(id: string, path: string, key: string) {
      return this.angularFirestore.collection(this.prependTenant(path)).doc(id)
          .update({
            [key]: firestore.FieldValue.delete()
          });
    }
}
