import {Injectable} from '@angular/core';
import {customAlphabet} from 'nanoid';
import {FirestoreProxyService} from './firestore-proxy.service';
import {Globals} from './globals';

@Injectable({
  providedIn: 'root'
})
export class TenantIdGeneratorService {

  constructor(private firestore: FirestoreProxyService) { }

    public async generateTenantId(): Promise<string>{

       try{
           const nanoid = customAlphabet('0123456789');
           let id: string;

           do {
               id = nanoid(6);
               console.log('nanoid:', id);
           } while (await this.tenantExists(id));

           return id;
        } catch (e) {
           console.log('fehler: ' + e);
           return '';
        }
    }

    private async tenantExists(id: string): Promise<boolean>{
        return await this.firestore.documentExists(id, Globals.tenantPath);
    }
}
