import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-color-choise',
  templateUrl: './color-choise.component.html',
  styleUrls: ['./color-choise.component.scss'],
})
export class ColorChoiseComponent implements OnInit {

  show_picker:boolean;

  @Input() curantColor:string|undefined;

  constructor() {
    if (!this.curantColor){
      this.curantColor = "#ffffff"}


    this.show_picker=false;

  }

  @Output() onselectColor = new EventEmitter();

  ngOnInit() {}
  public openPicker(){
    this.show_picker=true;
  }
  saveChange(){
    this.onselectColor.emit(this.curantColor)
    this.show_picker=false;

  }
  onChangeColorCode(event){
    this.curantColor=event.color.hex;
  }

}
