import { Injectable } from '@angular/core';
import {BehaviorSubject, EMPTY, from, of, Observable} from 'rxjs';
import {User} from '../models/user';
import {ProfilFreigabe} from '../models/profilFreigabe';
import {FirestoreProxyService} from './firestore-proxy.service';
import { map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {ContactsService} from './contacts.service';
import {Contact} from '../models/contact';
import firebase from 'firebase/compat';
import {Platform} from '@ionic/angular';
import {TenantService} from './tenant.service';
import {Globals} from "./globals";
import {ArbeitsgruppenService} from "./arbeitsgruppen.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
      private firestore: FirestoreProxyService,
      public contactsService: ContactsService,

      public arbeitsgruppenService:ArbeitsgruppenService,
      private platform: Platform,
      private tenantService: TenantService) {

      this.getCurrentUserObservable().subscribe( user => {
          this.currentUser$.next(user);
          Globals.currentTenentId=user.tenantId;
          arbeitsgruppenService.fullReloadOfArbeitsgruppen();
          console.log("User--",user.tenantId)




      });

  }

  currentFirebaseUser$: BehaviorSubject<firebase.User> = new BehaviorSubject<firebase.User>(null);
  currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);

  usersPath = 'users';
  arbeitsgruppenKey = 'arbeitsgruppen';

  userFreigabenKey = 'UserFreigaben';

  createUser(
      uid: string,
      firma: string,
      anrede: string,
      titel: string,
      vorname: string,
      nachname: string,
      email,
      tenantId: string,
      isOwner: boolean): Promise<void> {

      let roles = [];
      if (isOwner) {
          roles = ['admin'];
      }

      return this.firestore.setDocument(uid, this.usersPath, {
          uid,
          firma,
          anrede,
          titel,
          vorname,
          nachname,
          email,
          profilFreigabe: ProfilFreigabe.Privat,
          accountFreigegeben: false,
          tenantId,
          roles,
        })
        .then(() => this.contactsService.updateContacts(uid, vorname, nachname, firma))
        .catch(error => {
          console.log('Something went wrong with added user to firestore: ', error);
        });
  }

  getUserContacts(){
      let tenentId=this.currentUser$.getValue().tenantId;
      return this.firestore.getCollection("tenant/"+tenentId.toString()+"/users")
  }
  update_globalCurrantUserAndDependentComponents(){




          this.arbeitsgruppenService.fullReloadOfArbeitsgruppen()
      this.tenantService.tenant$.subscribe((tenent)=>{

          this.tenantService.updateCssColorVars(tenent.primaryColor,tenent.secondaryColor)
      })

          console.log("Arbeitsgruppen after update",Globals.currentTenentId,this.arbeitsgruppenService.arbeitsgruppen$.forEach(x=>{console.log(x)}))




  }
    createUserUnderTenent( //newer version mostly duplicate to prohibit database conflicts  <-Here
        uid: string,
        firma: string,
        anrede: string,
        titel: string,
        vorname: string,
        nachname: string,
        email,
        tenantId: string,
        isOwner: boolean): Promise<void> {

        let roles = [];
        if (isOwner) {
            roles = ['admin'];
        }
        Globals.currentTenentId=tenantId
        return this.firestore.setDocument(uid, "users", {
            uid,
            firma,
            anrede,
            titel,
            vorname,
            nachname,
            email,
            profilFreigabe: ProfilFreigabe.Privat,
            accountFreigegeben: false,
            tenantId,
            roles,
        })
            .then(() => this.contactsService.updateContacts(uid, vorname, nachname, firma))
            .catch(error => {
                console.log('Something went wrong with added user to firestore: ', error);
            });
    }


  isInRole(role: string): boolean{
    if (role === ''){
      of(true);
    }

    const user = this.currentUser$.getValue();
    if (user === undefined) {
        return false;
      }
    return (user.roles != null
          && user.roles.some(r => r === role));
  }

  getUser(uid: string): Observable<User> {
   return this.firestore.getDocument<User>(this.usersPath, uid);

  }

  getUserObservable(uid: string): Observable<User> {
    return this.firestore.getDocumentConnectedObservable<User>(this.usersPath, uid);
  }



    getCurrentUserObservable(): Observable<User> {   //Right
        return from(this.currentFirebaseUser$.asObservable())
            .pipe(switchMap(user => {
                if (user) {
                    return from(user.getIdTokenResult())
                        .pipe(switchMap(idTokenResult => {
                            console.log('fetch tenant');
                            this.tenantService.fetchTenant(idTokenResult.claims.tenantId);
                            return this.firestore.getDocumentConnectedObservableWithTenant<User>
                                    (idTokenResult.claims.tenantId, this.usersPath, user.uid);
                        }));
                }
                return EMPTY;
            }));
    }

  getCurrentUserId(): string {
    return this.currentFirebaseUser$.value?.uid;
  }

  getCurrentUserName(): string {
    const user = this.currentUser$.getValue();
    return user?.vorname + ' ' + user?.nachname;
  }

  getAllUser(): Observable<User[]> {
      return this.firestore.getCollection<User>(this.usersPath);
  }

  updateUser(user: Partial<User>) {
    this.firestore.mergeDocument(user.uid, this.usersPath, user);
    this.contactsService.updateContacts(user.uid, user.vorname, user.nachname, user.firma);
  }

  /// -- Kontaktfreigaben >>>
  updateProfilFreigabe(uid: string, profilFreigabe: ProfilFreigabe) {
      this.firestore.mergeDocument(uid, this.usersPath, { profilFreigabe });
  }


  updateFcmToken(fcmToken: string): Promise<void> {
      if (this.platform.is('capacitor')) {
          return this.firestore.mergeDocument(this.getCurrentUserId(), this.usersPath, {fcmToken});
      }
      return Promise.resolve();
  }

  setNotificationForTopic(topic: string, enabled: boolean) {
      if (enabled) {
          this.firestore.addValueToArray(this.getCurrentUserId(), this.usersPath, 'topics', [topic]);
      } else {
          this.firestore.removeValueFromArray(this.getCurrentUserId(), this.usersPath, 'topics', topic);
      }
  }

  removeUserFreigabe(uid: string, removeUid: string) {
    this.firestore.removeValueFromArray(uid, this.usersPath, this.userFreigabenKey, removeUid)
        .then(() => this.removeUserFreigabeLocal(removeUid));
  }

  addUserFreigabe(uid: string, freizugebenUid: string) {
    this.firestore.addValueToArray(uid, this.usersPath, this.userFreigabenKey, [freizugebenUid])
        .then(() => this.addUserFreigabeLocal(freizugebenUid));
  }

  private addUserFreigabeLocal(freizugebenUid: string) {
    this.currentUser$.toPromise().then(user => {
        if (!user.UserFreigaben) {
            user.UserFreigaben = [freizugebenUid];
        } else {
            const index = user.UserFreigaben.toString().indexOf(freizugebenUid);
            if (index === -1) {
                user.UserFreigaben.push(freizugebenUid);
            }
        }
    });
  }

  private removeUserFreigabeLocal(removeUid: string) {
      this.currentUser$.toPromise().then(user => {
        if (!user.UserFreigaben) {
          return;
        } else {
          const index = user.UserFreigaben.toString().indexOf(removeUid);
          if (index > -1) {
            user.UserFreigaben.splice(index, 1);
          }
        }
      });
  }

    allUsersMitBeidseitigerFreigabe(): Observable < User[] > {
        return this.firestore.query<User>(this.usersPath,
            [{
                fieldPath: 'UserFreigaben',
                filterOperator: 'array-contains',
                value: this.getCurrentUserId()
            }
            ]).pipe(map(x => x.map(y => {
            if (this.currentUser$.getValue().UserFreigaben.indexOf(y.uid) > -1) {
                return y;
            }
        })));
    }

    allUsersMitFreigabeAlle(): Observable < User[] > {
        return this.firestore.query(this.usersPath,
            [{
                fieldPath: 'profilFreigabe',
                filterOperator: '==',
                value: ProfilFreigabe.Alle
            }]);
    }

    allContactsWhereICanSeeDetails(): Observable < Contact[] > {
        const userFreigabeBeiderseits = this.allUsersMitBeidseitigerFreigabe();
        const userMitFreigabeAlle = this.allUsersMitFreigabeAlle();
        const userInDerselbenArbeitsgruppe = this.allUsersInDerselbenArbeitsgruppe();

        return userFreigabeBeiderseits
            .pipe(mergeMap(x => {
                return userMitFreigabeAlle
                    .pipe(mergeMap(y => {
                        return userInDerselbenArbeitsgruppe
                            .pipe(map(z => {
                                return x.concat(y).concat(z);
                            }))
                            .pipe(map(a => {
                                const result = [];
                                const userMap = new Map();
                                for (const item of a) {
                                    if (item != null && !userMap.has(item.uid)){
                                        userMap.set(item.uid, true);
                                        result.push(item);
                                    }
                                }
                                return result;
                            }));
                    }));
            }))
            .pipe(map(x => x.map(y => {
                if (y){
                return {
                    uid: y.uid,
                    vorname: `${y.vorname}`,
                    name: `${y.nachname}`,
                    firma: `${y.firma}`,
                    hidden: false
                }; }
                else{
                    return {
                        uid: '',
                        vorname: '',
                        name: '',
                        firma: '',
                        hidden: true
                    };
                }
            })))
            .pipe(tap(results => {
                results.sort((x, y) => `${x.name}${x.vorname}`.toLocaleLowerCase() > `${y.name}${y.vorname}`.toLocaleLowerCase() ? 1 : -1);
              }));

    }

    /// <<< Kontaktfreigaben ---

    /// --- Arbeitsgruppen >>>
  assignToArbeitsgruppe(uid: string, arbeitsgruppeId: number) {
    this.firestore.addValueToArray(uid, this.usersPath, this.arbeitsgruppenKey, [arbeitsgruppeId]);
  }

  removeFromArbeitsgruppe(uid: string, arbeitsgruppeId: number) {
    this.firestore.removeValueFromArray(uid, this.usersPath, this.arbeitsgruppenKey, arbeitsgruppeId);
  }

  allUsersInDerselbenArbeitsgruppe(): Observable < User[] > {
      const ag = this.currentUser$.getValue().arbeitsgruppen;
      if (!ag || !(ag.length > 0)){
          return of([]);
      }
      return this.firestore.query<User>(this.usersPath,
            [
                {
                    fieldPath: 'profilFreigabe',
                    filterOperator: '==',
                    value: ProfilFreigabe.Arbeitsgruppen
                },
                {
                    fieldPath: 'arbeitsgruppen',
                    filterOperator: 'array-contains-any',
                    value: this.currentUser$.getValue().arbeitsgruppen
                }
            ]);
    }
  /// <<< Arbeitsgruppen ---


  /// Kontakte einer Arbeitsgruppe >>>
  getUserNamesOfArbeitsgruppe(gruppennummer: number): Observable < {uid: string, vorname: string, name: string, firma: string}[] > {
    if (gruppennummer === 0)  {
        return EMPTY;
    }

    return this.firestore.query<User>(
      this.usersPath,
      [{
          fieldPath: 'arbeitsgruppen',
          filterOperator: 'array-contains',
          value: gruppennummer
      }])
      .pipe(map(u => {
          const result = u.map(x => {
              return {
                  uid: x.uid,
                  vorname: x.vorname,
                  name: x.nachname,
                  firma: x.firma
              };
          });

          return result.sort((a, b) =>
              (a.name.trim().toLocaleLowerCase() > b.name.trim().toLocaleLowerCase()) ? 1 :
                  (b.name.trim().toLocaleLowerCase() > a.name.trim().toLocaleLowerCase() ? -1 : 0));
      }));
  }


  /// Kontakte >>>
  getUserNames(userIds: string[]): Observable < {uid: string, vorname: string, name: string, firma: string}[] > {
      if (userIds.length === 0)  {
          return EMPTY;
      }

      return this.firestore.query<User>(
        this.usersPath,
        [{
            fieldPath: 'uid',
            filterOperator: 'in',
            value: userIds
        }])
        .pipe(map(u => {
            const result = u.map(x => {
                return {
                    uid: x.uid,
                    vorname: x.vorname,
                    name: x.nachname,
                    firma: x.firma
                };
            });

            return result.sort((a, b) =>
                (a.name.trim().toLocaleLowerCase() > b.name.trim().toLocaleLowerCase()) ? 1 :
                    (b.name.trim().toLocaleLowerCase() > a.name.trim().toLocaleLowerCase() ? -1 : 0));
        }));
    }


    /// Kontakte >>>
    getContacts(userIds: string[]): Observable < Contact[] > {

      return this.firestore.query<User>(
        this.usersPath,
        [{
            fieldPath: 'uid',
            filterOperator: 'in',
            value: userIds
        }])
          .pipe(map(x => x.map(y => {
              if (y){
              return {
                  uid: y.uid,
                  vorname: `${y.vorname}`,
                  name: `${y.nachname}`,
                  firma: `${y.firma}`,
                  hidden: false
              }; }
              else{
                  return {
                      uid: '',
                      vorname: '',
                      name: '',
                      firma: '',
                      hidden: true
                  };
              }
          })))
          .pipe(tap(results => {
              results.sort((x, y) => `${x.name}${x.vorname}`.toLocaleLowerCase() > `${y.name}${y.vorname}`.toLocaleLowerCase() ? 1 : -1);
            }));
  }getAllContacts(): Observable < Contact[] > {

        return this.firestore.query<User>(
            this.usersPath,
            [{
                fieldPath: 'tenantId',
                filterOperator: 'in',
                value: [this.currentUser$.getValue().tenantId]
            }])
            .pipe(map(x => x.map(y => {
                if (y){
                    return {
                        uid: y.uid,
                        vorname: `${y.vorname}`,
                        name: `${y.nachname}`,
                        firma: `${y.firma}`,
                        hidden: false
                    }; }
                else{
                    return {
                        uid: '',
                        vorname: '',
                        name: '',
                        firma: '',
                        hidden: true
                    };
                }
            })))
            .pipe(tap(results => {
                results.sort((x, y) => `${x.name}${x.vorname}`.toLocaleLowerCase() > `${y.name}${y.vorname}`.toLocaleLowerCase() ? 1 : -1);
            }));
    }


}
