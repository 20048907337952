import {Component, Input, OnInit, Output, EventEmitter, OnDestroy} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {ContactsService} from '../../services/contacts.service';
import {UserService} from '../../services/user.service';
import {Contact} from '../../models/contact';
import {error} from "protractor";


@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit, OnDestroy {

  @Input() isContactModus: boolean;
  @Input() isChatAddModus: boolean;
  @Input() excludedUids: string[];
  @Output() checkboxChecked = new EventEmitter<string>();
  @Output() checkboxUnchecked = new EventEmitter<string>();

  constructor(
    private contactsService: ContactsService,
    private userService: UserService) { }

    contacts: Contact[];
    allContacts: Observable<Contact[]>;
    subs: Subscription;
    currentUserId = this.userService.getCurrentUserId();


    ngOnInit() {
      this.setAllContacts();
      this.filterList(null);
    }


    ngOnDestroy() {
      this.subs?.unsubscribe();
    }


    setAllContacts() {
      this.allContacts = new Observable<Contact[]>();

      if (this.isContactModus)
      {
        // Alle Kontakte
        this.allContacts = this.userService.getAllContacts();
        this.allContacts.subscribe(x=>{
          console.log("all-users-isContact",x)
      })

      }

      if (this.isChatAddModus)
      {
        // Alle erlaubten Kontakte für einen Chat minus der bereits ausgewählten Kontakte
        this.allContacts = this.userService.getAllContacts()//this.userService.allContactsWhereICanSeeDetails()
        .pipe(map(c => {
          let users = c.filter(x => x && x.uid !== this.userService.getCurrentUserId() && x.hidden !== true);

          if (this.excludedUids && this.excludedUids.length > 0) {
            users = users.filter(x => !this.excludedUids.some(uid => uid === x.uid));
          }
          return users;
        }));
      }
    }


    async filterList(evt) {

      let searchTerm: string = null;

      if (evt != null && evt.srcElement != null) {
        searchTerm = (evt.srcElement.value as string);
      }

      // Liste Filtern
      if (searchTerm) {
        console.log(this.allContacts)
         this.subs = this.allContacts
          .subscribe(c => {
            this.contacts = c.filter(x => //(x.uid !== this.currentUserId)  &&
                                          (('' + x.vorname + x.name + x.firma).toLowerCase()
                                            .indexOf(searchTerm.toLowerCase()) > -1)).sort((a, b) =>
                                            a.name.localeCompare(b.name));
          });
      }
      else {
        this.subs = this.allContacts
          .subscribe(c => {
            this.contacts = c.filter(x => x.uid !== this.currentUserId).sort((a, b) =>
            a.name.localeCompare(b.name));
          },(error)=>{console.log(error)});
        }
    }


    checkboxClicked($event: any, contactUid: string) {

      if ($event.detail.checked) {
        this.checkboxChecked.emit(contactUid);
      } else {
        this.checkboxUnchecked.emit(contactUid);
      }
    }
}
