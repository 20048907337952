import { Injectable } from '@angular/core';

import {
    ActionPerformed,
    PushNotifications, PushNotificationSchema,
    Token
} from '@capacitor/push-notifications';
import {LocalNotifications} from '@capacitor/local-notifications';

import {Platform} from '@ionic/angular';
import {UserService} from './user.service';
import {Router} from '@angular/router';
import {NavigationService} from './navigation.service';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
      private platform: Platform,
      private userService: UserService,
      private router: Router,
      private navigationService: NavigationService) {
      if (this.isUsingPush()) {
          this.initPush();
      }
  }

  fcmToken: string;

  private isUsingPush() {
      return this.platform.is('capacitor');
  }

  private initPush() {
    // Request permission to use push notifications
    // iOS will prompt user and return if they granted permission or not
    // Android will just grant without prompting
    PushNotifications.requestPermissions().then( result => {
      if (result.receive === 'granted') {
        // Register with Apple / Google to receive push via APNS/FCM
        PushNotifications.register();
      } else {
        // Show some error
      }
    });

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
        (token: Token) => {
          console.log('Push registration success, token: ' + token.value);
          this.fcmToken = token.value;

          if (this.userService.getCurrentUserId()) {
              this.updateUserFcmToken();
          }
        }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log('Error on registration: ' + JSON.stringify(error));
        }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
        (notification: PushNotificationSchema) => {
          console.log('Push received: ' + JSON.stringify(notification));

          if (this.router.url.indexOf(notification.data.topic) === -1) {
              LocalNotifications.schedule(
                  {
                      notifications: [
                          {
                              id: 1,
                              title: notification.title,
                              body: notification.body
                          }]
                  });
            }
        }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: ActionPerformed) => {
          console.log('Push action performed: ' + JSON.stringify(notification));

          this.checkNotificationForFeeds(notification.notification);
        }
    );
  }

    checkNotificationForFeeds(notification: PushNotificationSchema) {
        console.log(notification.data.url);
        console.log(notification.data.topic);
        if (notification.data.url && notification.data.topic) {
            if (notification.data.url === '/chats') {
                this.navigationService.navigateToChat(notification.data.topic);
            }
            else if (notification.data.url === '/news') {
                this.navigationService.navigateToNewsFeed(notification.data.topic);
            }
            else if (notification.data.url === '/contacts') {
                this.navigationService.navigateToContact(notification.data.topic);
            }
        }
    }

    resetBadgeCount() {
        try {
            if (this.isUsingPush()) {
                PushNotifications.removeAllDeliveredNotifications();
            }
        }finally {
        }
    }

    updateUserFcmToken() {
        this.userService.updateFcmToken(this.fcmToken);
    }
}
