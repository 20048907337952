import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {FirestoreProxyService} from './firestore-proxy.service';
import {Contact} from '../models/contact';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactsService {

  constructor(private firestore: FirestoreProxyService) { }

  contactPath = 'contactList';
  contactDocId = 'all';

  updateContacts(uid: string, vorname: string, name: string, firma: string): Promise<void> {
    return this.firestore.mergeDocument(this.contactDocId, this.contactPath, {
      [uid]: {
        vorname,
        name,
        firma
      }
    });
  }

  allContacts(): Observable<Contact[]> {

    this.firestore.getDocumentConnectedObservable(this.contactPath, this.contactDocId).forEach(x => {
        console.log('Contact: ', x);
    }).then(r => {
        console.log('Contact: ', r);
    })
      return;
        /*
        .pipe(map(c => {
          const contacts: Contact[] = [];

          Object.keys(c).forEach(key => {
              if (!c[key].hidden) {
                  contacts.push({
                      uid: key,
                      vorname: c[key].vorname,
                      name: c[key].name,
                      firma: c[key].firma,
                      hidden: c[key].hidden
                  });
              }
          });
          contacts.sort((a, b) => (this.sortString(a) > this.sortString(b)) ? 1 :
                                  (this.sortString(b) > this.sortString(a)) ? -1 : 0);

          return contacts;
        }));

         */
  }

  sortString(contact: Contact): string {
    let sort = '';

    if (contact != null)
    {
        if (contact.vorname != null) {
          sort += contact.vorname;
        }

        if (contact.name != null) {
          sort += contact.name;
        }

        if (contact.firma != null) {
          sort += contact.firma;
        }
    }

    return sort.toLocaleLowerCase();
  }

}
