import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';
import {Event} from '../../models/event';
import moment from 'moment';
import {UserService} from '../../services/user.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit {

  @Input() event: Event;
  @Output() onDeleteEvent: EventEmitter<Event> = new EventEmitter();

  constructor(private userService: UserService) { }

  ngOnInit() {}

  formatTime(date: Date): string {
    return moment(date).format('HH:mm');
  }

  deleteEvent(event: Event) {
    if (this.onDeleteEvent) {
      this.onDeleteEvent.emit(event);
    }
  }

  isAllowedToDeleteEvent(): boolean {
    return this.userService.isInRole('redaktion') || this.userService.isInRole('admin');
  }
}
