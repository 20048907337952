import { Injectable } from '@angular/core';
import firebase from 'firebase/compat/app';
import 'firebase/compat/storage';
import {FileUploadTask} from '../models/FileService/fileUploadTask';
import {FileDownloadTask} from '../models/FileService/fileDownloadTask';
import {v4 as uuidv4} from 'uuid';
import StringFormat = firebase.storage.StringFormat;

@Injectable({
  providedIn: 'root'
})
export class FileService {

  constructor() { }

  uploadBase64(data: any, width: number, height: number, format: string): FileUploadTask {
    const uuid = uuidv4();
    const uploadTask = firebase.storage().ref(uuid).putString(data, StringFormat.BASE64, {
      customMetadata: {
        width: width.toString(),
        height: height.toString(),
        format
      }
    });
    return new FileUploadTask(uploadTask, uuid, width, height, format);
  }

  downloadFile(fileName: string) {
    const promise = firebase.storage().ref(fileName).getDownloadURL();
    return new FileDownloadTask(promise);
  }
}
