import { Component, Input } from '@angular/core';
import {ModalController} from '@ionic/angular';


@Component({
    selector: 'app-picture-modal',
    templateUrl: 'picture-modal.component.html',
    styleUrls: ['./picture-modal.component.scss'],
})
export class PictureModalComponent {

    @Input() picture: string;

    constructor(private modalController: ModalController) {
    }

    dismiss() {
        this.modalController.dismiss({
            dismissed: true
        });
    }
}
